<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="APP类型" prop="appType">
                <a-select placeholder="请选择APP类型" v-model="queryParam.appType" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.sys_app_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="更新包大小" prop="upHeSize">
                  <a-input v-model="queryParam.upHeSize" placeholder="请输入更新包大小" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="其它(应用市场)应用 【内部使用文件库】" prop="upUrl">
                  <a-input v-model="queryParam.upUrl" placeholder="请输入其它(应用市场)应用 【内部使用文件库】" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="版本号名称" prop="versionName">
                  <a-input v-model="queryParam.versionName" placeholder="请输入版本号名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="版本号" prop="versionCode">
                  <a-input v-model="queryParam.versionCode" placeholder="请输入版本号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="强制升级否 0强制 1可跳过" prop="compulsoryUp">
                  <a-input v-model="queryParam.compulsoryUp" placeholder="请输入强制升级否 0强制 1可跳过" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="计划更新时间" prop="planUpTime">
                  <a-input v-model="queryParam.planUpTime" placeholder="请输入计划更新时间" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="下载地址" prop="downloadUrl">
                  <a-input v-model="queryParam.downloadUrl" placeholder="请输入下载地址" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['app:version/info:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['app:version/info:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['app:version/info:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['app:version/info:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :appTypeOptions="dict.type.sys_app_type"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="appType" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_app_type']" :value="record.appType"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['app:version/info:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['app:version/info:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['app:version/info:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['app:version/info:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageVersionInfo,listVersionInfo, delVersionInfo } from '@/api/app/versionInfo'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'VersionInfo',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['sys_app_type'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        appType: null,
        upNotes: null,
        upHeSize: null,
        upUrlType: null,
        upUrl: null,
        versionName: null,
        versionCode: null,
        upType: null,
        compulsoryUp: null,
        planUpType: null,
        planUpTime: null,
        downloadUrl: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: '主键标识',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: 'APP类型',
          dataIndex: 'appType',
          //scopedSlots: { customRender: 'appType' },
          ellipsis: true,
          align: 'center',
          customRender:function (e) {
            if(e === '0'){
              return "安卓";
            }else if(e === '1') {
              return "苹果";
            } else {
              return "通用";
            }
          }
        },
        {
          title: '更新说明',
          dataIndex: 'upNotes',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '更新包大小',
          dataIndex: 'upHeSize',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '更新地址类型: 0内部 1其它(应用市场)',
          dataIndex: 'upUrlType',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '其它(应用市场)应用 【内部使用文件库】',
          dataIndex: 'upUrl',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '版本号名称',
          dataIndex: 'versionName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '版本号',
          dataIndex: 'versionCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '更新类型',
          dataIndex: 'upType',
          ellipsis: true,
          align: 'center',
          customRender:function (e) {
            if(e === '0'){
              return "热更新";
            }else if(e === '1') {
              return "整包更新";
            } else {
              return "其他";
            }
          }
        },
        {
          title: '强制升级',
          dataIndex: 'compulsoryUp',
          ellipsis: true,
          align: 'center',
          customRender:function (e) {
            if(e === '0'){
              return "强制";
            }else if(e === '1') {
              return "可跳过";
            }
          }
        },
        /*{
          title: '计划更新时间: 0及时更新 1计划更新',
          dataIndex: 'planUpType',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '计划更新时间',
          dataIndex: 'planUpTime',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '下载地址',
          dataIndex: 'downloadUrl',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '上传时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询APP版本升级 - 记录信息列表 */
    getList () {
      this.loading = true
     pageVersionInfo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        appType: undefined,
        upNotes: undefined,
        upHeSize: undefined,
        upUrlType: undefined,
        upUrl: undefined,
        versionName: undefined,
        versionCode: undefined,
        upType: undefined,
        compulsoryUp: undefined,
        planUpType: undefined,
        planUpTime: undefined,
        downloadUrl: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delVersionInfo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('app/version/info/export', {
            ...that.queryParam
          }, `APP版本升级 - 记录信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
